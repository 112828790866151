// eslint-disable-next-line no-redeclare,no-unused-vars
function administrateField(li, param) {
  var object = param.object;
  var key = li.attr('key');
  var info = $(
    '<span class="admin-info position-absolute px-2">' + key + '</span>'
  ).appendTo(li.label ? li.label : li);
  if (!config) return;

  var context = function (type) {
    if (!key) key = li.attr('key');
    var settings = fields[object][key];
    if (!settings) settings = {};
    if (type == 'tooltip') {
      var pm = {object: object, type: 'tooltip', key: key};
      pm.tip = prompt('Tooltip', settings.tip);
      if (!pm.tip) return;
      saveAdminSettings(pm);
      var help = li.find('.fa-question-circle');
      if (!help[0])
        help = $('<i class="fal fa-question-circle"></i>').appendTo(
          li.find('label')
        );
      help.addTooltip(pm.tip);
      fields[object][key].tip = pm.tip;
    } else if (type == 'add') {
      buildPromptPopup(
        ['Variablenname', 'Label', 'DB-Typ'],
        function (key, label, fieldType) {
          key = keyName(key);
          if (!key) return false;
          if (fields[object][key] || inArray(key, systemVars)) {
            alert('Variablenname bereits vergeben');
            return false;
          }
          var pm = {
            object: object,
            type: 'fieldAdd',
            key: key,
            label: label,
            fieldType: fieldType,
            noLog: true,
          };
          saveAdminSettings(pm, function (xhr) {
            if (xhr.error) return;
            fields[object][key] = {label: label, type: fieldType};
            buildFormLine(li, param, key).insertAfter(li);
            param.view.formSave();
          });
        }
      );
    } else if (type == 'copy') {
      var buildPromptPopupPm = $.extend({}, dialogForms.copyField);
      buildPromptPopupPm.key.value = key;
      buildPromptPopupPm.label.value = settings.label;
      buildPromptPopup(buildPromptPopupPm, function (values) {
        var settingsNew = $.extend(true, {}, settings);
        settingsNew.label = values.label;
        $.each(
          ['decimal', 'number', 'date', 'maxlength', 'text'],
          function (i, option) {
            if (settingsNew[option]) delete settingsNew[option];
          }
        );
        var saveAdminSettingsPm = {
          object: object,
          type: 'fieldAdd',
          key: values.key,
          label: values.label,
          fieldType: settingsNew.type,
          settings: settingsNew,
          noLog: true,
        };
        saveAdminSettings(saveAdminSettingsPm, function (xhr) {
          if (xhr.error) return;
          fields[object][values.key] = settingsNew;
          buildFormLine(li, param, values.key).insertAfter(li);
          param.view.formSave();
        });
      });
    } else if (type == 'hide') {
      li.remove();
      $(
        '<option value="' +
          key +
          '">' +
          (settings && settings.label ? settings.label : key) +
          '</option>'
      ).appendTo(param.view.find('#missing'));
      param.view.formSave();
    } else if (type == 'remove') {
      if (!confirm('Wirklich löschen?')) return;
      var saveAdminSettingsPm2 = {
        object: object,
        type: 'delete',
        key: key,
        noLog: true,
      };
      saveAdminSettings(saveAdminSettingsPm2, function () {
        li.remove();
        delete fields[object][key];
        param.view.formSave();
      });
    } else if (type == 'options2')
      ajax({
        object: 'settings',
        objectSub: 'settingsClient',
        func: 'buildSettingsView',
        modal: true,
        open: true,
        keyObject: object,
        key: key,
        title: 'Mandant',
      });
    else if (type == 'options3')
      ajax({
        object: 'settings',
        objectSub: 'settingsIndustry',
        func: 'buildSettingsView',
        modal: true,
        open: true,
        keyObject: object,
        key: key,
        title: 'Branche',
      });
    else if (type == 'options4')
      ajax({
        object: 'settings',
        objectSub: 'settingsGlobal',
        func: 'buildSettingsView',
        modal: true,
        open: true,
        keyObject: object,
        key: key,
        title: 'Core',
      });
    else if (type.indexOf('options') === 0) {
      var pm2 = {
        type: 'options',
        object: 'admin',
        id: object,
        modal: true,
      };
      var roleOption;
      if (type.length > 7) roleOption = type.slice(7);
      [pm2.row, pm2.settings] = optionsField({
        row: fields[object][key],
        settings: fields.admin,
        object: object,
        objectSub: param.objectSub,
        top: param.view,
        role: roleOption,
      });
      pm2.title = 'Feld: ' + settings.label + ' (' + key + ')';
      if (roleOption) pm2.title += ' - Rolle ' + roleOption;
      pm2.save = function (value, element, option) {
        var pmSave = {object: object, key: key, type: 'option'};
        value = element.val();
        if (pm2.settings[option].field == 'checkbox' && !value) value = '';
        else if (pm2.settings[option].field == 'table' && value) {
          var val = {};
          $.each(value, function (i, row) {
            if (!row.value) row.value = '';
            if (!row.key) row.key = '_';
            // else if (isNumeric(row.key)) row.key += ' ';
            if (!isArray(row.value) && inArray(option, ['labels']));
            val[row.key] = row.value; // make sure its an assoc array
          });
          value = val;
        } else if (
          !(inArray(option, ['read', 'hidden']) && value === 1) &&
          (pm2.settings[option].array ||
            inArray(option, [
              'labels',
              'values',
              'tableFields',
              'tableEdit',
              'index',
              'excelFields',
              'fields',
              'apiFields',
              'filter',
              'downloader',
              'searchVisible',
              'read',
              'hidden',
            ])) &&
          value
        ) {
          if (value.indexOf('|') > 0) value = value.split('|');
          else value = value.split(',');
        }
        // if (param.objectSub && els[els.length-1].hasClass('active')) option += ucfirst(param.objectSub);
        // if (param.objectSub && element.line.objectSub) option += ucfirst(param.objectSub);
        // if (element.line.lang == 'en') option += 'En';
        // if (!value) value = null;

        if (roleOption) option += roleOption;
        pmSave.sub = option;
        if (value !== null) pmSave.value = value;
        saveAdminSettings(pmSave);
        setObjectValue(fields, [key, option], value);
        var li2 = buildFormLine(li.parent(), param, key);
        li.replaceWith(li2);
        li = li2;
      };
      pm2.size = 'xl';
      pm2.view = buildPopup(pm2);
      buildForm(pm2.view.body, pm2, parameter.admin.form);
      if (role == 'superadmin') administrateDetail(pm2);
    }
  };

  if (
    inArray(param.object, ['admin', 'adminParameter', 'adminMenu']) &&
    role != 'superadmin'
  )
    return;
  var pmContext = {options: 'Optionen'};
  pmContext.add = 'neues Feld';
  pmContext.copy = 'Feld kopieren';
  pmContext.hide = 'Feld ausblenden';
  pmContext.remove = 'Feld löschen';
  pmContext.options2 = 'Optionen client';
  pmContext.options3 = 'Optionen industry';
  pmContext.options4 = 'Optionen global';

  // var roleOptions = {};
  // $.each(fields.user.roll.values, function (i, role) {
  //   role = ucfirst(role);
  //   var amount = 0;
  //   if (object && key)
  //     $.each(fields[object][key], function (option) {
  //       if (option.indexOf(role) > 0) amount++;
  //     });
  //   roleOptions['options' + role] = 'Optionen ' + fields.user.roll.labels[i];
  //   if (amount) roleOptions['options' + role] += ' (' + amount + ')';
  // });
  // if (!isEmptyObject(roleOptions))
  //   pmContext.roleOptions = ['Rollenoptionen', roleOptions];

  contextMenu(info, pmContext, context);
}
